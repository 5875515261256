import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import axios from "axios";
import api from "./api";

export default function Header() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const offcanvasSearchRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.post("", {
          method_name: "category",
        });
        if (
          response.data &&
          response.data.histro_Price &&
          response.data.histro_Price.length > 0
        ) {
          setCategories(response.data.histro_Price);
        } else {
          throw new Error("No categories found");
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError("Failed to load categories.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchInputChange = async (event) => {
    const query = event.target.value.trim();
    setQuery(query);

    if (query) {
      try {
        const response = await api.post("", {
          method_name: "keyword_suggestion",
          keyword_suggestion: query,
        });

        if (response.data && response.data.histro_Price) {
          setSuggestions(response.data.histro_Price);
        } else {
          setSuggestions([]);
        }
      } catch (err) {
        console.error("Error fetching keyword suggestions:", err);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    if (query) {
      if (offcanvasSearchRef.current) {
        const offcanvas = bootstrap.Offcanvas.getInstance(
          offcanvasSearchRef.current
        );
        if (offcanvas) {
          offcanvas.hide();
        }
      }
      navigate(`/filter?query=${query}`);
    }
  };

  return (
    <div className="back_color">
      <div
        className="offcanvas offcanvas-end"
        data-bs-scroll="true"
        id="offcanvasSearch"
        aria-labelledby="Search"
        ref={offcanvasSearchRef}
      >
        <div className="offcanvas-header justify-content-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="order-md-last">
            <span className="d-flex justify-content-between align-items-center mb-4">
            <div className="three"><span className="top_design">Search</span></div>
            </span>
            <form
              id="search-form"
              className="text-center"
              onSubmit={handleSearch}
            >
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                name="search"
                placeholder="Search for more than 20,000 products"
                value={query}
                onChange={handleSearchInputChange}
              />
            </form>
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li key={index}>
                    <a
                      href={`/filter?query=${suggestion.name}`}
                      onClick={() => setQuery(suggestion.name)}
                    >
                      {suggestion.name} - {suggestion.category}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row border-bottom">
          <div className="col-sm-6 col-md-4 col-lg-4 text-center text-sm-start d-flex justify-content-start align-items-center">
            <a href="/">
              <img src={logo} alt="logo" className="img-fluid" />
            </a>
          </div>

          <div className="col-md-8 col-lg-8 offset-md-0 col-lg-8 d-none d-lg-block">
            <form
              id="search-form"
              className="text-center"
              onSubmit={handleSearch}
            >
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control border-0 bg-transparent"
                  name="search"
                  placeholder="Search for more than 20,000 products"
                  value={query}
                  onChange={handleSearchInputChange}
                />
              </div>
            </form>
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li key={index}>
                    <a
                      href={`/filter?query=${suggestion.name}`}
                      onClick={() => setQuery(suggestion.name)}
                    >
                      {suggestion.name} - {suggestion.category}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="col-sm-6 col-md-8 col-lg-8 category_item">
            <div className="container">
              <div className="row py-3">
                <div className="d-flex justify-content-center align-items-center header_back_color">
                  <ul className="d-flex justify-content-end list-unstyled m-0">
                    <li className="d-lg-none">
                      <a
                        href="#"
                        className="rounded-circle p-2 mx-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasSearch"
                        aria-controls="offcanvasSearch"
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>

                  <nav className="main-menu d-flex navbar navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>

                    <div
                      className="offcanvas offcanvas-end"
                      id="offcanvasNavbar"
                      aria-labelledby="offcanvasNavbarLabel"
                    >
                      <div className="offcanvas-header justify-content-center back4">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="offcanvas-body back4">
                        <div className="scrollable-container">
                          {loading ? (
                            <div>Loading categories...</div>
                          ) : error ? (
                            <div>{error}</div>
                          ) : categories.length === 0 ? (
                            <div>No categories found</div>
                          ) : (
                            categories.map((category) => (
                              <a
                                href={`/category/${category.slug}`}
                                key={category.slug}
                                className="nav-link"
                              >
                                <div className="category-item">
                                  {category.name}
                                </div>
                              </a>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="container slider_category">
            <div className="row py-3">
              <div className="d-flex justify-content-center align-items-center header_back_color">
                <nav className="main-menu d-flex navbar navbar-expand-lg">
                  <div className="offcanvas-body back4">
                    <Swiper
                      spaceBetween={10}
                      slidesPerView="auto"
                      loop={true}
                      grabCursor={true}
                      centeredSlides={true}
                    >
                      {loading ? (
                        <div>Loading categories...</div>
                      ) : error ? (
                        <div>{error}</div>
                      ) : categories.length === 0 ? (
                        <div>No categories found</div>
                      ) : (
                        categories.map((category) => (
                          <SwiperSlide key={category.slug}>
                            <a href={`/category/${category.slug}`} className="nav-link">
                              <div className="category-item">
                                {category.name.length > 20 ? category.name.slice(0, 18) + '...' : category.name}
                              </div>
                            </a>
                          </SwiperSlide>
                        ))
                      )}
                    </Swiper>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
