import React, { useEffect } from "react";

const AdsenseContent = ({
  adUnit,
  adSlot,
  style,
  format,
  responsive,
  layoutkey,
  onAdLoad,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7182611503421709";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        onAdLoad && onAdLoad();
      } catch (e) {
        console.error("Adsense script failed to load:", e);
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [adUnit, adSlot]);

  return (
    <div style={{ textAlign: "center" }}>
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-client={adUnit}
        data-ad-slot={adSlot}
        data-ad-format={format}
        data-full-width-responsive={responsive}
        data-ad-layout-key={layoutkey}
      ></ins>
    </div>
  );
};

export default AdsenseContent;
