import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import axios from "axios";
import api from "./api";
import AdsenseContent from "./AdsenseContent";

const Brand = () => {
  const { store_name } = useParams();
  const [metaData, setMetaData] = useState(null);
  const pageName = "Brand";
  const [adLoaded, setAdLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [page, setPage] = useState(1);
  const productsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        const response = await api.post("", {
          method_name: "brand_product_filter",
          brand: store_name,
          page,
          per_page: productsPerPage,
        });
        const productsData = response.data.histro_Price || [];
        setCategoryProducts(productsData);
        setTotalPages(response.data.histro_Price[0]?.total_page || 0);

        const fetchMetaTags = async () => {
          try {
            const metaResponse = await api.post("", {
              method_name: "meta_keyword",
              page_name: pageName,
              store_name: store_name,
            });

            const pageMeta = metaResponse.data.histro_Price[0];
            if (pageMeta) {
              setMetaData(pageMeta);
            } else {
              console.error("No meta data found for this page.");
            }
          } catch (error) {
            console.error("Error fetching meta data:", error);
          }
        };
        fetchMetaTags();
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchCategoryProducts();

    const fetchProducts = async () => {
      try {
        const response = await api.post("", {
          method_name: "top_products",
        });
        if (
          response.data &&
          response.data.histro_Price &&
          response.data.histro_Price.length > 0
        ) {
          setProducts(response.data.histro_Price);
        } else {
          throw new Error("No Products found");
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products.");
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [store_name, page]);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const isNextPageAvailable = () => {
    return page < totalPages;
  };

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <>
            <title>{metaData.title}</title>
            <meta property="og:title" content={metaData.title} />
            <meta name="twitter:title" content={metaData.title} />
          </>
        )}
        {metaData.description && (
          <>
            <meta property="og:description" content={metaData.description} />
            <meta name="twitter:description" content={metaData.description} />
          </>
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <>
            <meta property="og:image" content={metaData.image} />
            <meta property="og:image:secure_url" content={metaData.image} />
            <meta name="twitter:image" content={metaData.image} />
          </>
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
      </>
    );
  };

  const renderProductsAndAds = () => {
    const items = [];

    categoryProducts.forEach((product, index) => {
      items.push(
        <div key={product.id} className="col-md-4">
          {product.sell_price && (
            <span className="badge bg-success position-absolute m-3">
              -
              {Math.round(
                ((parseFloat(product.price) - parseFloat(product.sell_price)) /
                  parseFloat(product.price)) *
                  100
              )}
              %
            </span>
          )}
          <figure>
            <a href={`/product/${product.slug}`} title={product.name}>
              <img
                src={product.image}
                className="tab-image"
                alt={product.name}
              />
            </a>
          </figure>
          <a className="productsLink" href={`/product/${product.slug}`}>
            <h3 className="product_name">{product.name}</h3>
          </a>
          <span className="rating">
            {[...Array(Math.floor(product.rate))].map((_, index) => (
              <i
                key={index}
                className="fa fa-star text-primary"
                aria-hidden="true"
              ></i>
            ))}
            {product.rate % 1 !== 0 && (
              <i
                className="fa fa-star-half-alt text-primary"
                aria-hidden="true"
              ></i>
            )}
            {[...Array(5 - Math.ceil(product.rate))].map((_, index) => (
              <i
                key={index + Math.floor(product.rate)}
                className="fa fa-star-o text-primary"
                aria-hidden="true"
              ></i>
            ))}
          </span>
          <span className="price">₹{product.sell_price || product.price}</span>
          <div className="d-flex align-items-center justify-content-between">
            <a className="productsLink" href={`/brand/${product.store_name}`}>
              <span className="text-xs font-semibold uppercase text-gray-500">
                {product.store_name}
              </span>
            </a>
            <time dateTime={product.updated_at} title={product.updated_at}>
              {product.updated_at}
            </time>
          </div>
        </div>
      );

      const count = categoryProducts.length;
      if (count === 2 && index === 1) {
        items.push(
          <div key={`ad_${index}`} className="col-md-4">
            <AdsenseContent
              adUnit="ca-pub-7182611503421709"
              adSlot="4087535386"
              style={{ display: "block" }}
              format="fluid"
              responsive=""
              layoutkey="-dr-1s+14+4x-1y"
              onAdLoad={() => setAdLoaded(true)}
            />
          </div>
        );
      } else if (count === 4 && index === 3) {
        items.push(
          <div key={`ad_${index}`} className="col-md-4">
            <AdsenseContent
              adUnit="ca-pub-7182611503421709"
              adSlot="7466445563"
              style={{ display: "block" }}
              format="fluid"
              responsive=""
              layoutkey="-er+4+2m-4t-15"
              onAdLoad={() => setAdLoaded(true)}
            />
          </div>
        );
      } else if (count === 6 && index === 5) {
        items.push(
          <div key={`ad_${index}`} className="col-md-4">
            <AdsenseContent
              adUnit="ca-pub-7182611503421709"
              adSlot="4167432607"
              style={{ display: "block" }}
              format="fluid"
              responsive=""
              layoutkey="-f+rx+2c-5m+1q"
              onAdLoad={() => setAdLoaded(true)}
            />
          </div>
        );
      }
    });

    return items;
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>{renderMetaTags()}</Helmet>
        <section className="py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="three">
                  <h1>{store_name.toUpperCase()}</h1>
                </div>
                <div className="row">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      role="tabpanel"
                      aria-labelledby="nav-all-tab"
                    >
                      <div className="product-grid row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                        {renderProductsAndAds()}
                      </div>
                      {categoryProducts.length > 0 && totalPages > 1 && (
                        <div className="d-flex justify-content-between mt-3">
                          <button
                            className="btn btn-secondary"
                            onClick={handlePreviousPage}
                            disabled={page <= 1}
                          >
                            Previous
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={handleNextPage}
                            disabled={!isNextPageAvailable()}
                          >
                            Next
                          </button>
                        </div>
                      )}
                      {categoryProducts.length === 0 && (
                        <div className="product-grid productFound row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 row-cols-xl-12">
                          <div>
                            No products found for this ecommerce platform.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Brand;