import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main";
import Product from "./components/Product";
import Category from "./components/Category";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Filter from "./components/Filter";
import Contact from "./components/Contact";
import About from "./components/About";
import Brand from "./components/brand";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/product/:slug" element={<Product />} />
          <Route path="/category/:slug" element={<Category />} />
          <Route path="/filter" element={<Filter />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/brand/:store_name" element={<Brand />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
