import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "./api";
import AdsenseContent from "./AdsenseContent";

const Brand = () => {
  const { store_name } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [page, setPage] = useState(1);
  const productsPerPage = 15;
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        setLoading(true);
        const response = await api.post("", {
          method_name: "brand_product_filter",
          brand: store_name,
          page,
          per_page: productsPerPage,
        });

        const productsData = response.data.histro_Price || [];
        if (productsData.length > 0) {
          setCategoryProducts(productsData);
          setTotalPages(productsData[0]?.total_page || 0);
        } else {
          setCategoryProducts([]);
          setError("No products found for this brand.");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to fetch products.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryProducts();
  }, [store_name, page]);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const renderProductsAndAds = () => {
    const items = [];
    const ads = [
      {
        adUnit: "ca-pub-7182611503421709",
        adSlot: "4087535386",
        layoutkey: "-dr-1s+14+4x-1y",
      },
      {
        adUnit: "ca-pub-7182611503421709",
        adSlot: "7466445563",
        layoutkey: "-er+4+2m-4t-15",
      },
      {
        adUnit: "ca-pub-7182611503421709",
        adSlot: "4167432607",
        layoutkey: "-f+rx+2c-5m+1q",
      },
    ];

    let adIndex = 0;

    categoryProducts.forEach((product, index) => {
      items.push(
        <div className="col-md-4" key={product.id}>
          <div className="product-item position-relative">
            {product.sell_price && (
              <span className="badge bg-success position-absolute m-3">
                -
                {Math.round(
                  ((parseFloat(product.price) -
                    parseFloat(product.sell_price)) /
                    parseFloat(product.price)) *
                    100
                )}
                %
              </span>
            )}
            <figure>
              <a href={`/product/${product.slug}`} title={product.name}>
                <img
                  src={product.image}
                  className="tab-image"
                  alt={product.name}
                />
              </a>
            </figure>
            <a className="productsLink" href={`/product/${product.slug}`}>
              <h3 className="product_name">{product.name}</h3>
            </a>
            <span className="rating">
              {[...Array(Math.floor(product.rate))].map((_, index) => (
                <i
                  key={index}
                  className="fa fa-star text-primary"
                  aria-hidden="true"
                ></i>
              ))}
              {product.rate % 1 !== 0 && (
                <i
                  className="fa fa-star-half-alt text-primary"
                  aria-hidden="true"
                ></i>
              )}
              {[...Array(5 - Math.ceil(product.rate))].map((_, index) => (
                <i
                  key={index + Math.floor(product.rate)}
                  className="fa fa-star-o text-primary"
                  aria-hidden="true"
                ></i>
              ))}
            </span>
            <span className="price">
              ₹{product.sell_price || product.price}
            </span>
            <div className="d-flex align-items-center justify-content-between">
              <a className="productsLink" href={`/brand/${product.store_name}`}>
                <span className="text-xs font-semibold uppercase text-gray-500">
                  {product.store_name}
                </span>
              </a>
              <time dateTime={product.updated_at} title={product.updated_at}>
                {product.updated_at}
              </time>
            </div>
          </div>
        </div>
      );

      if (page === 1 && [1, 2, 3].includes(index + 1) && adIndex < ads.length) {
        const ad = ads[adIndex];
        items.push(
          <div key={`ad_${index}`} className="col-md-4">
            <AdsenseContent
              adUnit={ad.adUnit}
              adSlot={ad.adSlot}
              style={{ display: "block" }}
              format="fluid"
              responsive=""
              layoutkey={ad.layoutkey}
            />
          </div>
        );
        adIndex++;
      }
    });

    return items;
  };

  return (
    <HelmetProvider>
      <div>
        <section className="py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="three">
                  <h1>{store_name.toUpperCase()}</h1>
                </div>
                <div className="row">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      role="tabpanel"
                      aria-labelledby="nav-all-tab"
                    >
                      {categoryProducts.length > 0 ? (
                        <div className="product-grid row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                          {renderProductsAndAds()}
                        </div>
                      ) : (
                        <div className="product-grid productFound row row-cols-12">
                          <div>
                            {error || "No products found for this page."}
                          </div>
                        </div>
                      )}
                      {categoryProducts.length > 0 && totalPages > 1 && (
                        <div className="d-flex justify-content-between mt-3">
                          <button
                            className="btn btn-secondary"
                            onClick={handlePreviousPage}
                            disabled={page <= 1 || loading}
                          >
                            Previous
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={handleNextPage}
                            disabled={page >= totalPages || loading}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Brand;
